.sectionContainer {
    // background: #0F172A;
    margin-top: 10rem;
}

.sectionHeading {
    font-weight: 600;
    // font-size: 56px;
    font-size: 3rem;
    line-height: 5rem;
    /* or 143% */
    text-align: center;
    letter-spacing: -0.04em;
}

.buttonCommon {
    @apply px-2 py-2 mx-4;
    // background: #46BDE1;
    border: 1px solid #46BDE1;
    color: #46BDE1;
    border-radius: 1.5rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
    // width: 323px;
    height: 120px;
    // cursor: pointer;
}
.img{
    width: 100%;
    padding: 10%;
    border-radius: 50%;
    background-color:lightblue;
}
.img:hover {
    padding: 2%;
    box-shadow: 2px 2px 10px 0px #46BDE1;
}

@media (min-width: 360px) and (max-width: 1023px) {
    .sectionContainer {
        // padding: 1.625rem 0 64px;
        padding: 0 0 64px;
        margin-top: 0;
    }

    .sectionHeading {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.875rem;
        text-align: center;
        letter-spacing: -0.04em;
    }

    .buttonContainer {
        flex-direction: column;
    }

    .buttonCommon {
        @apply my-2;
    }
}